// components/AdminPanel.js
import React from 'react';
import UserManagement from './UserManagement';

function AdminPanel() {
    return (
        <div>
            <UserManagement />
        </div>
    );
}

export default AdminPanel;
