// components/Login.js
import React, { useState } from 'react';
import API from '../services/api';
import '../styles/Login.css';
import '../styles/Form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        API.post('/auth/login', { username, password })
            .then((res) => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('role', res.data.role);
                window.location.href = '/';
            })
            .catch((err) => {
                console.error(err);
                setMessage('Login fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.');
            });
    };

    return (
        <main className="login-main">
            <div className="login-container">
                <h2>Login</h2>
                {message && <p className="error-message">{message}</p>}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label htmlFor="username">
                            <FontAwesomeIcon icon={faUser} className="input-icon" />
                            Benutzername:
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Benutzername"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            Passwort:
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Passwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" className="btn btn-submit">
                            <FontAwesomeIcon icon={faSignInAlt} /> Anmelden
                        </button>
                    </div>
                </form>
            </div>
        </main>
    );
}


export default Login;
