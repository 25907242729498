// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role') || 'guest';

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    window.location.href = '/login';
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-brand">
          <h1>Turmalin-Steine</h1>
        </div>
        <div className="navbar-links">
          {token ? (
            <>
              <Link to="/" className="nav-link">Steine</Link>
              {userRole === 'admin' && <Link to="/admin" className="nav-link">Adminbereich</Link>}
              <button onClick={handleLogout} className="btn-logout">Logout</button>
            </>
          ) : (
            <Link to="/login" className="nav-link">Login</Link>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
