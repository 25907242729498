// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './styles/Global.css'; // Import global styles
import './styles/App.css'; // Import global styles
import Header from './components/Header';
import Footer from './components/Footer';
import SteinListe from './components/SteinListe';
import SteinDetail from './components/SteinDetail';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';

function App() {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role') || 'guest';

  const isAuthenticated = !!token;

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        {isAuthenticated ? (
          <>
            <Route path="/" element={<SteinListe />} />
            {userRole !== 'guest' ? (
              <Route path="/steine/:id" element={<SteinDetail />} />
            ) : (
              <Route path="*" element={<Navigate to="/" />} />
            )}
            {userRole === 'admin' && <Route path="/admin" element={<AdminPanel />} />}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
