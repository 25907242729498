// components/Footer.js
import React from 'react';
import '../styles/Footer.css'; // Import the CSS file

function Footer() {
    return (
        <footer>
            <p>&copy; 2024 Turmalin-Steine</p>
        </footer>
    );
}

export default Footer;