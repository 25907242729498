// components/SteinListe.js
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import API from '../services/api';
import { Link, useNavigate, createSearchParams } from 'react-router-dom';
import Modal from './Modal';
import '../styles/SteinListe.css';
import '../styles/SearchForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function SteinListe() {
  const [steine, setSteine] = useState([]);
  const userRole = localStorage.getItem('role') || 'guest';
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    search: '',
    farbe: '',
    kategorie: '',
    serienBezeichnung: '',
    istSerie: false,
    status: '',
    gewichtMin: '',
    gewichtMax: '',
    preisProGrammMin: '',
    preisProGrammMax: '',
    durchmesserMin: '',
    durchmesserMax: '',
    kaufPreisMin: '',
    kaufPreisMax: '',
    kaufDatumVon: '',
    kaufDatumBis: '',
    verkaufPreisMin: '',
    verkaufPreisMax: '',
    verkaufDatumVon: '',
    verkaufDatumBis: '',
    // Weitere Parameter können hier hinzugefügt werden
  });
  

  // Zustand für das Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Zustand für das Filtern
  const [showFilter, setShowFilter] = useState(false);

  const [farbenOptions, setFarbenOptions] = useState([]);
  const [kategorieOptions, setKategorieOptions] = useState([]);
  const [serienBezeichnungOptions, setSerienBezeichnungOptions] = useState([]);


  const navigate = useNavigate();

  // Fetch Filter Options on mount
  useEffect(() => {
    fetchFilterOptions();
    fetchSerienBezeichnungen();
  }, []);

  // Debounced handleSearch Funktion
  const debouncedHandleSearch = useCallback(
    debounce(() => {
      setIsLoading(true);
      const params = new URLSearchParams();
      for (const key in searchParams) {
        if (searchParams[key]) {
          params.append(key, searchParams[key]);
        }
      }
      API.get(`/steine/search?${params.toString()}`)
        .then((res) => setSteine(res.data))
        .catch((err) => console.error(err));
        setIsLoading(false);
    }, 300),
    [searchParams]
  );

  // Effekt, um die Suche auszulösen, wenn sich searchParams ändern
  useEffect(() => {
    debouncedHandleSearch();
    return debouncedHandleSearch.cancel;
  }, [searchParams, debouncedHandleSearch]);

  const fetchFilterOptions = () => {
    API.get('/steine/farben')
      .then((res) => setFarbenOptions(res.data.filter((farbe) => farbe && farbe.trim() !== '')))
      .catch((err) => console.error(err));

    API.get('/steine/kategorien')
      .then((res) => setKategorieOptions(res.data.filter((kategorie) => kategorie && kategorie.trim() !== '')))
      .catch((err) => console.error(err));
  };


  const fetchSerienBezeichnungen = () => {
    API.get('/steine/serienBezeichnungen')
      .then((res) => setSerienBezeichnungOptions(res.data.filter((serien) => serien && serien.trim() !== '')))
      .catch((err) => console.error(err));
  };

  const handleReset = () => {
    setSearchParams({
      search: '',
      farbe: '',
      kategorie: '',
      serienBezeichnung: '',
      istSerie: false,
      status: '',
      gewichtMin: '',
      gewichtMax: '',
      preisProGrammMin: '',
      preisProGrammMax: '',
      durchmesserMin: '',
      durchmesserMax: '',
      kaufPreisMin: '',
      kaufPreisMax: '',
      kaufDatumVon: '',
      kaufDatumBis: '',
      verkaufPreisMin: '',
      verkaufPreisMax: '',
      verkaufDatumVon: '',
      verkaufDatumBis: '',
    });
    // Kein Bedarf an fetchSteine(), da debouncedHandleSearch die Daten aktualisiert
  };

  const handleSteinClick = (event, stein) => {
    if (event && event.target && (event.target.tagName === 'BUTTON' || event.target.tagName === 'svg' || event.target.tagName === 'path')) {
      return;
    }
    setSelectedImage(`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}`);
    setShowModal(true);
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    navigate({
      pathname: `/steine/${id}`,
      search: createSearchParams({ edit: 'true' }).toString(),
    });
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    if (window.confirm('Möchten Sie diesen Stein wirklich löschen?')) {
      API.delete(`/steine/${id}`)
        .then(() => {
          debouncedHandleSearch(); // Liste nach dem Löschen aktualisieren
        })
        .catch((err) => {
          console.error(err);
          alert('Fehler beim Löschen des Steins.');
        });
    }
  };

  const handleCardClick = (event, stein) => {
    // Verhindert die Navigation, wenn auf einen Button geklickt wurde
    if (
      event.target.closest('.button-group') ||
      event.target.tagName === 'BUTTON' ||
      event.target.tagName === 'svg' ||
      event.target.tagName === 'path'
    ) {
      return;
    }
    navigate(`/steine/${stein._id}`);
  };

  const handleImageClick = (event, stein) => {
    event.stopPropagation();
    setSelectedImage(`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}`);
    setShowModal(true);
  };

  return (
    <main>
      {/* Such- und Filterbereich */}
      <section className="search-filter-section">
        <div className="search-bar">
          {/* Suchfeld */}
          <input
            type="text"
            id="suchfeld"
            placeholder="Suche nach Name oder Notizen..."
            value={searchParams.search}
            onChange={(e) => setSearchParams({ ...searchParams, search: e.target.value })}
          />
          {/* Button zum Anzeigen/Ausblenden der Filter */}
          {userRole !== 'guest' && (
            <button className="toggle-filter-button" onClick={() => setShowFilter(!showFilter)}>
              {showFilter ? 'Filter ausblenden' : 'Filter anzeigen'}
            </button>
          )}
        </div>

        {userRole !== 'guest' && showFilter && (
          <div className="filters">
            <h2>Filteroptionen</h2>
            <form>
              {/* Allgemeine Filter */}
              <fieldset className="filter-group">
                <legend>Allgemein</legend>
                {/* Farbe, Kategorie, Status, Ist Teil einer Serie, Serienbezeichnung */}
                {/* Farbe Filter */}
                <div className="filter-item">
                  <label htmlFor="farbeFilter">Farbe:</label>
                  <select
                    id="farbeFilter"
                    value={searchParams.farbe}
                    onChange={(e) => setSearchParams({ ...searchParams, farbe: e.target.value })}
                  >
                    <option value="">Alle Farben</option>
                    {farbenOptions.map((farbe) => (
                      <option key={farbe} value={farbe}>
                        {farbe}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Kategorie Filter */}
                <div className="filter-item">
                  <label htmlFor="kategorieFilter">Kategorie:</label>
                  <select
                    id="kategorieFilter"
                    value={searchParams.kategorie}
                    onChange={(e) => setSearchParams({ ...searchParams, kategorie: e.target.value })}
                  >
                    <option value="">Alle Kategorien</option>
                    {kategorieOptions.map((kategorie) => (
                      <option key={kategorie} value={kategorie}>
                        {kategorie}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Status Filter */}
                <div className="filter-item">
                  <label htmlFor="statusFilter">Status:</label>
                  <select
                    id="statusFilter"
                    value={searchParams.status}
                    onChange={(e) => setSearchParams({ ...searchParams, status: e.target.value })}
                  >
                    <option value="">Alle Status</option>
                    <option value="potenzieller Kauf">Potenzieller Kauf</option>
                    <option value="gekauft">Gekauft</option>
                    <option value="verkauft">Verkauft</option>
                    <option value="noch keinen Kontakt">Noch keinen Kontakt</option>
                  </select>
                </div>

                {/* Ist Teil einer Serie (Checkbox) */}
                <div className="filter-item checkbox-item">
                  <label htmlFor="istSerieFilter">
                    <input
                      type="checkbox"
                      id="istSerieFilter"
                      checked={searchParams.istSerie}
                      onChange={(e) => setSearchParams({ ...searchParams, istSerie: e.target.checked })}
                    />
                    Teil einer Serie
                  </label>
                </div>

                {/* Serienbezeichnung (Dropdown) */}
                { (
                  <div className="filter-item">
                    <label htmlFor="serienBezeichnungFilter">Serienbezeichnung:</label>
                    <select
                      id="serienBezeichnungFilter"
                      value={searchParams.serienBezeichnung}
                      onChange={(e) => setSearchParams({ ...searchParams, serienBezeichnung: e.target.value })}
                    >
                      <option value="">Alle Serien</option>
                      {serienBezeichnungOptions.map((serien) => (
                        <option key={serien} value={serien}>
                          {serien}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

              </fieldset>

              {/* Maße */}
              <fieldset className="filter-group">
                <legend>Maße</legend>
                {/* Gewicht, Durchmesser */}
                {/* Gewicht Min */}
                <div className="filter-item">
                  <label htmlFor="gewichtMin">Gewicht von (g):</label>
                  <input
                    type="number"
                    id="gewichtMin"
                    step="0.01"
                    value={searchParams.gewichtMin}
                    onChange={(e) => setSearchParams({ ...searchParams, gewichtMin: e.target.value })}
                  />
                </div>

                {/* Gewicht Max */}
                <div className="filter-item">
                  <label htmlFor="gewichtMax">Gewicht bis (g):</label>
                  <input
                    type="number"
                    id="gewichtMax"
                    step="0.01"
                    value={searchParams.gewichtMax}
                    onChange={(e) => setSearchParams({ ...searchParams, gewichtMax: e.target.value })}
                  />
                </div>

                {/* Durchmesser Min */}
                <div className="filter-item">
                  <label htmlFor="durchmesserMin">Durchmesser von (mm):</label>
                  <input
                    type="number"
                    id="durchmesserMin"
                    step="0.01"
                    value={searchParams.durchmesserMin}
                    onChange={(e) => setSearchParams({ ...searchParams, durchmesserMin: e.target.value })}
                  />
                </div>

                {/* Durchmesser Max */}
                <div className="filter-item">
                  <label htmlFor="durchmesserMax">Durchmesser bis (mm):</label>
                  <input
                    type="number"
                    id="durchmesserMax"
                    step="0.01"
                    value={searchParams.durchmesserMax}
                    onChange={(e) => setSearchParams({ ...searchParams, durchmesserMax: e.target.value })}
                  />
                </div>
              </fieldset>

              {/* Preis */}
              <fieldset className="filter-group">
                <legend>Preis</legend>
                {/* Preis pro Gramm, Kaufpreis, Verkaufspreis */}
                {/* Preis pro Gramm Min */}
                <div className="filter-item">
                  <label htmlFor="preisProGrammMin">Preis pro Gramm von (€):</label>
                  <input
                    type="number"
                    id="preisProGrammMin"
                    step="0.01"
                    value={searchParams.preisProGrammMin}
                    onChange={(e) => setSearchParams({ ...searchParams, preisProGrammMin: e.target.value })}
                  />
                </div>

                {/* Preis pro Gramm Max */}
                <div className="filter-item">
                  <label htmlFor="preisProGrammMax">Preis pro Gramm bis (€):</label>
                  <input
                    type="number"
                    id="preisProGrammMax"
                    step="0.01"
                    value={searchParams.preisProGrammMax}
                    onChange={(e) => setSearchParams({ ...searchParams, preisProGrammMax: e.target.value })}
                  />
                </div>
                {/* Kaufpreis Min */}
                <div className="filter-item">
                  <label htmlFor="kaufPreisMin">Kaufpreis von (€):</label>
                  <input
                    type="number"
                    id="kaufPreisMin"
                    step="0.01"
                    value={searchParams.kaufPreisMin}
                    onChange={(e) => setSearchParams({ ...searchParams, kaufPreisMin: e.target.value })}
                  />
                </div>

                {/* Kaufpreis Max */}
                <div className="filter-item">
                  <label htmlFor="kaufPreisMax">Kaufpreis bis (€):</label>
                  <input
                    type="number"
                    id="kaufPreisMax"
                    step="0.01"
                    value={searchParams.kaufPreisMax}
                    onChange={(e) => setSearchParams({ ...searchParams, kaufPreisMax: e.target.value })}
                  />
                </div>

                {/* Verkaufsdetails analog hinzufügen */}
                {/* Verkaufspreis Min */}
                <div className="filter-item">
                  <label htmlFor="verkaufPreisMin">Verkaufspreis von (€):</label>
                  <input
                    type="number"
                    id="verkaufPreisMin"
                    step="0.01"
                    value={searchParams.verkaufPreisMin}
                    onChange={(e) => setSearchParams({ ...searchParams, verkaufPreisMin: e.target.value })}
                  />
                </div>

                {/* Verkaufspreis Max */}
                <div className="filter-item">
                  <label htmlFor="verkaufPreisMax">Verkaufspreis bis (€):</label>
                  <input
                    type="number"
                    id="verkaufPreisMax"
                    step="0.01"
                    value={searchParams.verkaufPreisMax}
                    onChange={(e) => setSearchParams({ ...searchParams, verkaufPreisMax: e.target.value })}
                  />
                </div>
                
              </fieldset>

              {/* Datum */}
              <fieldset className="filter-group">
                <legend>Datum</legend>
                {/* Kaufdatum, Verkaufsdatum */}

                {/* Kaufdatum Von */}
                <div className="filter-item">
                  <label htmlFor="kaufDatumVon">Kaufdatum von:</label>
                  <input
                    type="date"
                    id="kaufDatumVon"
                    value={searchParams.kaufDatumVon}
                    onChange={(e) => setSearchParams({ ...searchParams, kaufDatumVon: e.target.value })}
                  />
                </div>

                {/* Kaufdatum Bis */}
                <div className="filter-item">
                  <label htmlFor="kaufDatumBis">Kaufdatum bis:</label>
                  <input
                    type="date"
                    id="kaufDatumBis"
                    value={searchParams.kaufDatumBis}
                    onChange={(e) => setSearchParams({ ...searchParams, kaufDatumBis: e.target.value })}
                  />
                </div>

                {/* Verkaufsdatum Von */}
                <div className="filter-item">
                  <label htmlFor="verkaufDatumVon">Verkaufsdatum von:</label>
                  <input
                    type="date"
                    id="verkaufDatumVon"
                    value={searchParams.verkaufDatumVon}
                    onChange={(e) => setSearchParams({ ...searchParams, verkaufDatumVon: e.target.value })}
                  />
                </div>

                {/* Verkaufsdatum Bis */}
                <div className="filter-item">
                  <label htmlFor="verkaufDatumBis">Verkaufsdatum bis:</label>
                  <input
                    type="date"
                    id="verkaufDatumBis"
                    value={searchParams.verkaufDatumBis}
                    onChange={(e) => setSearchParams({ ...searchParams, verkaufDatumBis: e.target.value })}
                  />
                </div>
              </fieldset>

              {/* Buttons Zurücksetzen */}
              <div className="filter-buttons">
                <button onClick={handleReset} className="btn btn-reset">
                  Zurücksetzen
                </button>
              </div>
            </form>
          </div>
        )}
      </section>

      {/* Trennlinie */}
      <hr className="section-divider" />

      {/* Bereich zur Anzeige der Steine */}
      <section className="stones-list-section">
        {userRole === 'admin' && (
          <Link to="/steine/new" className="btn btn-add">
            Neuen Stein hinzufügen
          </Link>
        )}
        {isLoading ? (
          <p>Lade Steine...</p>
        ) : (
          <div className="stein-grid">
            {steine.map((stein) => (
              <div
                key={stein._id}
                className="stein-card"
                onClick={(e) => handleCardClick(e, stein)}
              >
                {stein.bildPfad && (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}`}
                    alt={stein.name}
                    onClick={(e) => handleImageClick(e, stein)}
                  />
                )}


                {/* Details */}
                <div className="stein-details">
                  <h3 className="stein-name">{stein.name}</h3>

                  {/* Kategorie */}
                  {stein.kategorie && (
                    <p className="stein-property">
                      <span className="property-label">Kategorie:</span>
                      <span className="property-value">{stein.kategorie}</span>
                    </p>
                  )}

                  {/* Status */}
                  {stein.status && (
                    <p className="stein-property">
                      <span className="property-label">Status:</span>
                      <span className="property-value">{stein.status}</span>
                    </p>
                  )}

                  {/* Durchmesser */}
                  {stein.durchmesser && (
                    <p className="stein-property">
                      <span className="property-label">Durchmesser:</span>
                      <span className="property-value">{stein.durchmesser} mm</span>
                    </p>
                  )}

                  {/* Gewicht */}
                  {stein.gewicht && (
                    <p className="stein-property">
                      <span className="property-label">Gewicht:</span>
                      <span className="property-value">{stein.gewicht} g</span>
                    </p>
                  )}

                  {/* Preis pro Gramm */}
                  {stein.preisProGramm && (
                    <p className="stein-property">
                      <span className="property-label">Preis pro Gramm:</span>
                      <span className="property-value">€ {stein.preisProGramm}</span>
                    </p>
                  )}

                  {/* Serienbezeichnung */}
                  {stein.serienBezeichnung && (
                    <p className="stein-property">
                      <span className="property-label">Serie:</span>
                      <span className="property-value">{stein.serienBezeichnung}</span>
                    </p>
                  )}

                  {/* Kaufpreis */}
                  {stein.kaufPreis && (
                    <p className="stein-property">
                      <span className="property-label">Kaufpreis:</span>
                      <span className="property-value">€ {stein.kaufPreis}</span>
                    </p>
                  )}

                  {/* Verkaufspreis */}
                  {stein.verkaufPreis && (
                    <p className="stein-property">
                      <span className="property-label">Verkaufspreis:</span>
                      <span className="property-value">€ {stein.verkaufPreis}</span>
                    </p>
                  )}
                </div>




                {userRole === 'admin' && (
                  <div className="button-group">
                    <button
                      onClick={(e) => handleEdit(e, stein._id)}
                      className="bearbeiten-button"
                      title="Stein bearbeiten"
                    >
                      <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                    </button>
                    <button onClick={(e) => handleDelete(e, stein._id)} className="loeschen-button">
                      <FontAwesomeIcon icon={faTrash} /> Löschen
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}  
      </section>

      {/* Modal für Bildanzeige */}
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <img src={selectedImage} alt="Stein" className="modal-image" />
        </Modal>
      )}
    </main>
  );
}

export default SteinListe;
