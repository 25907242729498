// components/UserManagement.js
import React, { useState, useEffect } from 'react';
import API from '../services/api';
import '../styles/UserManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUserPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    role: 'user',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    API.get('/users')
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    API.post('/users', formData)
      .then((res) => {
        setUsers([...users, res.data]);
        setFormData({ username: '', password: '', role: 'user' });
      })
      .catch((err) => {
        console.error(err);
        alert('Fehler beim Hinzufügen des Benutzers.');
      });
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setFormData({ username: user.username, password: '', role: user.role });
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    API.put(`/users/${editingUser._id}`, formData)
      .then((res) => {
        fetchUsers();
        setEditingUser(null);
        setFormData({ username: '', password: '', role: 'user' });
      })
      .catch((err) => {
        console.error(err);
        alert('Fehler beim Aktualisieren des Benutzers.');
      });
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setFormData({ username: '', password: '', role: 'user' });
  };

  const handleDeleteUser = (id) => {
    if (window.confirm('Benutzer wirklich löschen?')) {
      API.delete(`/users/${id}`)
        .then(() => {
          setUsers(users.filter((user) => user._id !== id));
        })
        .catch((err) => {
          console.error(err);
          alert('Fehler beim Löschen des Benutzers.');
        });
    }
  };

  return (
    <main className="user-management">
      <h2>Benutzerverwaltung</h2>

      {/* Rechte der Benutzerrollen */}
      <section className="role-rights">
        <h3>Rechte der Benutzerrollen</h3>
        <div className="role-rights-grid">
          <div className="role-card admin">
            <h4>Admin</h4>
            <ul>
              <li>Kann Benutzer erstellen (einschließlich Gast-Benutzer)</li>
              <li>Hat Zugriff auf den Adminbereich</li>
              <li>Kann Steine erstellen, bearbeiten und löschen</li>
            </ul>
          </div>
          <div className="role-card user">
            <h4>User</h4>
            <ul>
              <li>Kann sich anmelden und die Liste der Steine ansehen</li>
              <li>Kann Steindetails ansehen, aber keine Änderungen vornehmen</li>
            </ul>
          </div>
          <div className="role-card guest">
            <h4>Gast</h4>
            <ul>
              <li>Kann sich anmelden und die Liste der Steine ansehen</li>
              <li>Kann keine Steindetails einsehen</li>
              <li>Die Idee ist, dass der Admin einstellt, welche Steine und welche Details der Gast sehen kann. (Diese Feature ist jedoch noch nicht implementiert.)</li>
            </ul>
          </div>
        </div>
      </section>


      {/* Formular zum Hinzufügen oder Bearbeiten von Benutzern */}
      <section className="user-form-section">
        <h3>{editingUser ? 'Benutzer bearbeiten' : 'Neuen Benutzer hinzufügen'}</h3>
        <form onSubmit={editingUser ? handleUpdateUser : handleAddUser} className="user-form">
          <div className="form-group">
            <label htmlFor="username">Benutzername:</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Benutzername"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{editingUser ? 'Neues Passwort:' : 'Passwort:'}</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={editingUser ? 'Neues Passwort (optional)' : 'Passwort'}
              value={formData.password}
              onChange={handleChange}
              required={!editingUser} // Passwort ist beim Bearbeiten optional
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Rolle:</label>
            <select id="role" name="role" value={formData.role} onChange={handleChange}>
              <option value="user">Benutzer</option>
              <option value="admin">Admin</option>
              <option value="guest">Gast</option>
            </select>
          </div>
          <div className="button-group">
            {editingUser ? (
              <>
                <button type="submit" className="btn btn-submit">
                  <FontAwesomeIcon icon={faSave} /> Aktualisieren
                </button>
                <button type="button" className="btn btn-cancel" onClick={handleCancelEdit}>
                  <FontAwesomeIcon icon={faTimes} /> Abbrechen
                </button>
              </>
            ) : (
              <button type="submit" className="btn btn-submit">
                <FontAwesomeIcon icon={faUserPlus} /> Benutzer hinzufügen
              </button>
            )}
          </div>
        </form>
      </section>

      {/* Benutzerliste */}
      <section className="user-list-section">
        <h3>Bestehende Benutzer</h3>
        <table className="user-table">
          <thead>
            <tr>
              <th>Benutzername</th>
              <th>Rolle</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    onClick={() => handleEditUser(user)}
                    className="btn-icon"
                    title="Benutzer bearbeiten"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user._id)}
                    className="btn-icon"
                    title="Benutzer löschen"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </main>
  );
}

export default UserManagement;
